import Feature from 'ol/Feature';
import { Polygon } from 'ol/geom';
import Point from 'ol/geom/Point';
import { Icon, Style } from 'ol/style';
import { van } from '../assets/images';

export const addVanMarkers = (lonLatArray: [any[]]) => {
  const iconStyle = new Style({
    image: new Icon({
      //src: "https://i.imgur.com/MqUPLWi.png",
      src: van,
      scale: 0.6,
    }),
  });
  var polyCoords =
    '[ -58.55328986109618,-34.437972376951066], [-58.54492136896972,-34.45828382458903], [-58.50462386072997,-34.45000103088837], [-58.55328986109618,-34.437972376951066]';
  const polygonFeature = new Feature(
    new Polygon(JSON.parse('[[' + polyCoords + ']]'))
  );
  const features: any[] = [];
  const setfeatures = () => {
    /* let feature = new Feature({
      geometry: new Point([-58.527566257789888, -34.45]),
      zIndex: 999999,
      name: 'Combi',
    });
    feature.setStyle(iconStyle);
    features.push(feature); */
    if (lonLatArray !== undefined) {
      lonLatArray[0].forEach((ll) => {
        if (ll[0] !== 0 && ll[1] !== 0) {
          if (polygonFeature.getGeometry()?.intersectsCoordinate(ll)) {
            let feature = new Feature({
              geometry: new Point(ll),
              zIndex: 999999,
              name: 'Combi UdeSA',
            });
            feature.setStyle(iconStyle);
            features.push(feature);
          }
        }
      });
    }
  };
  setfeatures();
  return features;
};
