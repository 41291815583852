import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Icon, Style } from 'ol/style';
import { hotelPinMap, trenPinMap, udesaPinMap } from '../assets/images';

export const addLocationMarkers = (lonLatArray: any[]) => {
  let features = lonLatArray.map((item, i) => {
    let feature = new Feature({
      name: item[1],
      geometry: new Point(item[0]),
    });

    switch (i) {
      case 0:
        var iconStyle = new Style({
          image: new Icon({
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            src: udesaPinMap,
            scale: 0.2,
            //offsetOrigin: [0, 0],
          }),
          zIndex: 9999,
        });
        feature.setStyle(iconStyle);
        break;
      case 1:
        var iconStyle = new Style({
          image: new Icon({
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            src: trenPinMap,
            scale: 0.2,
            //offsetOrigin: [0, 0],
          }),
          zIndex: 9999,
        });
        feature.setStyle(iconStyle);
        break;
      case 2:
        var iconStyle = new Style({
          image: new Icon({
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            src: udesaPinMap,
            scale: 0.2,
            //offsetOrigin: [0, 0],
          }),
          zIndex: 9999,
        });
        feature.setStyle(iconStyle);
        break;
      case 3:
        var iconStyle = new Style({
          image: new Icon({
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            src: hotelPinMap,
            scale: 0.2,
            //offsetOrigin: [0, 0],
          }),
          zIndex: 9999,
        });
        feature.setStyle(iconStyle);
        break;

      default:
        var iconStyle = new Style({
          image: new Icon({
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            src: udesaPinMap,
            scale: 0.2,
            //offsetOrigin: [0, 0],
          }),
          zIndex: 9999,
        });
        feature.setStyle(iconStyle);
        break;
    }

    return feature;
  });
  return features;
};
