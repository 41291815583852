import React, { useRef, useState, useEffect } from "react"
import "./Map.css";
import "ol/ol.css";
import "ol-ext/dist/ol-ext.css";
import MapContext from "./MapContext";
import {Map as MapOl, Overlay} from "ol";
import {View} from "ol";
import {defaults} from 'ol/interaction';
import { juanpablotoniolo } from '../../assets/images';

const Map = ({ children, zoom, center, extent, screen }) => {
	const mapRef = useRef();
	const refContainer = useRef();
	const [map, setMap] = useState(null);

	// const container = document.getElementById('popup');
	// const content = document.getElementById('popup-content');
	const closer = document.getElementById('popup-closer');

	// on component mount
	useEffect(() => {
		let options = {
			view: new View({projection: 'EPSG:4326', center }),
			layers: [],
			controls: [],
			interactions: defaults({
			 	doubleClickZoom :false,
				dragAndDrop: false,
				keyboardPan: false,
				keyboardZoom: false,
				mouseWheelZoom: false,
				dragPan : false,
				select: false
			}),
			overlays: []
		};

		let mapObject = new MapOl(options);
		mapObject.setTarget(mapRef.current);
		

		/* const vector = new VectorLayer({ source: new VectorSource() });
		mapObject.addLayer(vector);
		const f = new Feature(new Point(config.udesaLonLat));
		f.setProperties({
		hello: "hello",
		world: "World!"
		}); */

		//vector.getSource().addFeature(f);
		/* 			var select = new Select({
					hitTolerance: 1,
					multi: true,
					condition: singleClick
					});
					mapObject.addInteraction(select);

					var popupext = new PopupFeature({
					popupClass: "default anim",
					select: select,
					visible: true,
					canFix: false})
					
					mapObject.addOverlay(popupext); */
		const overlay = new Overlay({
			element: refContainer.current,
			autoPan: {
				animation: {
				duration: 250,
				},
			},
			});
		
		mapObject.on('singleclick', function (evt) {
			const feature = mapObject.forEachFeatureAtPixel(evt.pixel, function (feature) {
				return feature;
			  });
			  if (!feature) {
				overlay.setPosition(undefined)
				if(screen[0]> 640){
					mapObject.getView().fit(extent,{
						size: [screen[0]-350,screen[1]-350],
						maxZoom: 20,
						minZoom:5
					})
				} else {
					mapObject.getView().fit(extent,{
						size: [screen[0]-1000,screen[1]-100],
						maxZoom: 20,
						minZoom:5
					})
				}
				return;
			  }
			  refContainer.current.innerHTML = feature.get('name');
			  overlay.setPosition(evt.coordinate);

		});

		if(closer) closer.onclick = function () {
			overlay.setPosition(undefined);
			closer.blur();
			if(screen[0]> 640){
				map.getView().fit(extent,{
					size: [screen[0]-350,screen[1]-350],
					maxZoom: 20,
					minZoom:5
				})
			} else {
				map.getView().fit(extent,{
					size: [screen[0]-100,screen[1]-100],
					maxZoom: 20,
					minZoom:5
				})
			}
			return false;
		  };
		mapObject.addOverlay(overlay);
		if(screen[0]> 640){
			mapObject.getView().fit(extent,{
				size: [screen[0]-350,screen[1]-350],
				maxZoom: 20,
				minZoom:5
			})
		} else {
			mapObject.getView().fit(extent,{
				size: [screen[0]-100,screen[1]-100],
				maxZoom: 20,
				minZoom:5
			})
		}

		setMap(mapObject);
		
		return () => mapObject.setTarget(undefined);
	}, []);
	


	// zoom change handler
	useEffect(() => {
		if (!map) return;
		map.getView().setZoom(zoom);
	}, [zoom]);

	// center change handler
	useEffect(() => {
		if (!map) return;
		map.getView().setCenter(center)
	}, [center])

	// center change handler
	useEffect(() => {
		if (!map) return;
		//map.zoomToExtent(extent)
		if(screen[0]> 640){
			map.getView().fit(extent,{
				size: [screen[0]-350,screen[1]-350],
				maxZoom: 20,
				minZoom:5
			})
		} else {
			map.getView().fit(extent,{
				size: [screen[0]-100,screen[1]-100],
				maxZoom: 20,
				minZoom:5
			})
		}
	}, [screen])

	return (
		<MapContext.Provider value={{ map }} style={{'touchAction': 'none'}}>
			<div style={{height:"100%", position:"fixed",'touchAction': 'none'/* , justifyContent:'center', textAlign:'center' */}} ref={mapRef} className="ol-map">
				{children}
				{/* <h1 style={{paddingTop:'200px', fontSize:'64px'}}>SITIO EN MANTENIENTO</h1> */}
			</div>
			{/* <div id="legende"><img style={{width:"200px"}} src={juanpablotoniolo}/></div> */}
			<div ref={refContainer} id="popup" className="ol-popup">
                <a href="#" id="popup-closer" className="ol-popup-closer"></a>
                <div id="popup-content"></div>
            </div>
		</MapContext.Provider>
	)
}

export default Map;