import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';

export const styles = {
  route1: new Style({
    stroke: new Stroke({
      width: 8,
      color: 'rgba(27, 109, 175, 1)',
    }),
  }),
  route2: new Style({
    stroke: new Stroke({
      width: 8,
      color: 'rgba(200, 56, 27, 1)',
    }),
  }),
  route3: new Style({
    stroke: new Stroke({
      width: 8,
      color: 'rgba(236, 167, 45, 1)',
    }),
  }),
};
