import axios from 'axios';
import { addVanMarkers } from './addVanMarkers';
import mapConfig from '../config.json';
import { van } from '../assets/images';

export const getVanData = async (
  setFeaturesLocVan: (value: any) => void,
  featuresLocVan: any,
  setIsNotfirst: (value: any) => void,
  isNotfirst?: boolean
) => {
  let url = 'https://my.loccate.com/api-v2/tracker/get_states';
  let horarios = mapConfig.horarios;
  /* let url = 'http://www.gps228.com/Ajax/DevicesAjax.asmx'; */
  // let url = 'http://localhost:3030/locations/4d74a18d8eb9b723';
  // let url = 'https://remote.udesa.edu.ar:8443/locations/4d74a18d8eb9b723';

  // const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
  // const proxyUrl = 'https://proxyserver-dpbt.onrender.com/?url=';
  const proxyUrl = 'https://proxyservereverywhere.onrender.com/';
  /* var xmlBodyStr = `<?xml version="1.0" encoding="utf-8"?>
  <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
    <soap12:Body>
      <GetDevicesByUserID xmlns="http://tempuri.org/">
        <UserID>18544</UserID>
        <isFirst>false</isFirst>
        <TimeZones>'-3:00'</TimeZones>
        <DeviceID>625031</DeviceID>
      </GetDevicesByUserID>
    </soap12:Body>
  </soap12:Envelope>`; */

  /*   if (isHash === '') {
    setHash(getHash().then((h) => h));
  } */

  /*   var hash = '*****';
  var trackers = '[1918010]'; */

  const responseAxios = () =>
    new Promise(async function (resolve, reject) {
      await axios;
      /* .post(proxyUrl + url, xmlBodyStr, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers':
              'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
            'content-type': 'application/soap+xml',
          },
        }) */
      /* fetch(
        'https://my.loccate.com/api-v2/tracker/get_states?hash=' +
          hash +
          '&trackers=' +
          trackers,
        {
          method: 'GET',
          redirect: 'follow',
        }
      ) */
      fetch('https://combis-coords.s3.us-east-2.amazonaws.com/coords.json')
        /*await fetch(proxyUrl + url, {
        method: 'POST',
        headers: {
          origin: 'http://localhost',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/soap+xml',
        },
        body: xmlBodyStr,
      })*/
        .then(async function (response) {
          let data = (await response.text()).replace(/\\/g, '');
          // const dataAux1 = data.replaceAll('new Date(', ' ');
          // const dataAux2 = dataAux1.replaceAll('),', ',');
          data = data.replaceAll('devices:', '"devices":');
          data = data.replaceAll('id:', '"id":');
          data = data.replaceAll('locationID:', '"locationID":');
          data = data.replaceAll('groupID:', '"groupID":');
          data = data.replaceAll('serverUtcDate:', '"serverUtcDate":');
          data = data.replaceAll('deviceUtcDate:', '"deviceUtcDate":');
          data = data.replaceAll('baiduLat:', '"baiduLat":');
          data = data.replaceAll('baiduLng:', '"baiduLng":');
          data = data.replaceAll('latitude:', '"latitude":');
          data = data.replaceAll('longitude:', '"longitude":');
          data = data.replaceAll('ofl:', '"ofl":');
          data = data.replaceAll('speed:', '"speed":');
          data = data.replaceAll('course:', '"course":');
          data = data.replaceAll('dataType:', '"dataType":');
          data = data.replaceAll('dataContext:', '"dataContext":');
          data = data.replaceAll('distance:', '"distance":');
          data = data.replaceAll('isStop:', '"isStop":');
          data = data.replaceAll('stopTimeMinute:', '"stopTimeMinute":');
          data = data.replaceAll('carStatus:', '"carStatus":');
          data = data.replaceAll('status:', '"status":');
          return JSON.parse(data);
        })
        .then(function (json) {
          const vans: any[] = [];
          /* console.log(json + 'THEN');
          vans.push(
            json['1918010']['gps']['location']['lng'],
            json['1918010']['gps']['location']['lat']
          ); */
          const notanerror = json.devices.forEach((element: any) => {
            if (element.latitude !== null && element.longitude !== null)
              /* element.status != 'Offline' && */
              vans.push([
                element.longitude.toString().replaceAll("'", ''),
                element.latitude.toString().replaceAll("'", ''),
                element.course.toString().replaceAll("'", ''),
              ]);
          });
          if (vans[0][0] !== null) {
            setFeaturesLocVan(addVanMarkers([vans]));
          } else {
            throw new Error('Whoops!');
          }
        })
        .catch((error) => {
          if (error && !featuresLocVan) {
            setFeaturesLocVan(addVanMarkers([[0, 0]]));
          } else {
            // TO TRIGGER REFRESH EVEN ON NO NEW DATA
            if (featuresLocVan === '[0,0]') {
              setFeaturesLocVan(addVanMarkers([[1, 1]]));
            } else {
              setFeaturesLocVan(addVanMarkers([[0, 0]]));
            }
          }
        });
    });

  const currentDate = new Date();
  const now = new Date(currentDate.getTime());
  if (now.getHours() < 8 || now.getHours() >= 21) {
    setTimeout(function () {
      if (-1 === -1) {
        /* responseAxios(); */
      }
    }, 3000);
  } else {
    if (isNotfirst) {
      setTimeout(function () {
        if (-1 === -1) {
          responseAxios();
        }
      }, 3000);
    } else {
      setIsNotfirst(true);
      responseAxios();
    }
  }
};
