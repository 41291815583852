import Feature from 'ol/Feature';
import polylineEstacion from '../route_estacion.json';
import polylineEstacionBack from '../route_estacion_back.json';
import polylineRibera from '../route_ribera_2.json';
import polylineRiberaBack from '../route_ribera_back.json';
import polylineJacaranda from '../route_jacaranda.json';
import polylineJacarandaBack from '../route_jacaranda_back.json';
import Polyline from 'ol/format/Polyline';
import { styles } from './createStyles';

export const createFeatureRoutes = () => {
  const routingEstacion = new Polyline({
    factor: 1e6,
  }).readGeometry(polylineEstacion.routes[0].geometry);
  const featureRouteEstacion = new Feature({
    type: 'route',
    geometry: routingEstacion,
    zIndex: 999,
    name: 'Ruta Entrada Campus por Vito Dumas',
  });
  featureRouteEstacion.setStyle(styles.route1);

  const routingEstacionBack = new Polyline({
    factor: 1e5,
  }).readGeometry(polylineEstacionBack.routes[0].geometry);
  const featureRouteEstacionBack = new Feature({
    type: 'route',
    geometry: routingEstacionBack,
    zIndex: 999,
    name: 'Ruta Entrada Campus por Vito Dumas',
  });
  featureRouteEstacionBack.setStyle(styles.route1);

  const routingRibera = new Polyline({
    factor: 1e5,
  }).readGeometry(polylineRibera.routes[0].geometry);
  const featureRouteRibera = new Feature({
    type: 'route',
    geometry: routingRibera,
    zIndex: 999,
    name: 'Ruta Entrada Campus por Ribera',
  });
  featureRouteRibera.setStyle(styles.route2);

  const routingJacaranda = new Polyline({
    factor: 1e5,
  }).readGeometry(polylineJacaranda.routes[0].geometry);
  const featureRouteJacaranda = new Feature({
    type: 'route',
    geometry: routingJacaranda,
    zIndex: 999,
    name: 'Ruta Residencia Jacaranda',
  });
  featureRouteJacaranda.setStyle(styles.route3);

  const routingRiberaBack = new Polyline({
    factor: 1e5,
  }).readGeometry(polylineRiberaBack.routes[0].geometry);
  const featureRouteRiberaBack = new Feature({
    type: 'route',
    geometry: routingRiberaBack,
    zIndex: 999,
    name: 'Ruta Entrada Campus por Ribera Vuelta',
  });
  featureRouteRiberaBack.setStyle(styles.route2);

  const routingJacarandaBack = new Polyline({
    factor: 1e5,
  }).readGeometry(polylineJacarandaBack.routes[0].geometry);
  const featureRouteJacarandaBack = new Feature({
    type: 'route',
    geometry: routingJacarandaBack,
    zIndex: 999,
    name: 'Ruta Residencia Jacaranda Vuelta',
  });
  featureRouteJacarandaBack.setStyle(styles.route3);

  return [
    featureRouteJacaranda,
    featureRouteRibera,
    featureRouteEstacion,
    featureRouteJacarandaBack,
    featureRouteRiberaBack,
    featureRouteEstacionBack,
  ];
};
